<template>
<main class="content content--error">
  <div class="container">
    <div class="page-error">
      <img src="@/assets/img/error-code.svg" alt="" class="img-fluid">
                <h1 class="page-title mt-5">Leider wurde die gewünschte Seite nicht gefunden</h1>
                <p class="title-third">Am besten startest du wieder
                    auf unserer Homepage und nutzt dort
                    unsere Navigation und Suchfunktion oder
                    prüft die URL, die du eingegeben hast auf Tippfehler.</p>
            </div>
            <div class="row mt-4 justify-content-center">
                <div class="w-auto mb-2">
                    <router-link :to="{ name: 'home' }" class="btn btn-outline-primary min-width-170">Zurück</router-link>
                </div>
                <div class="w-auto mb-2">
        <router-link :to="{ name: 'home' }" class="btn btn-primary min-width-170">Homepage</router-link>
      </div>
    </div>
  </div>
</main>
</template>
<script>
import { useMeta } from 'vue-meta'
export default {
  name: 'notFound',
  setup () {
    useMeta({
      title: 'Seite wurde nicht gefunden'
    })
  }
}
</script>
